<template>
  <div class="loader-body">
    <div>
      <img src="@/assets/loader.gif" alt="loader">
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheLoader'
}
</script>

<style lang="scss" scoped>
.loader-body {
  background-color: #FFFFFFC7;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  & > div {
    width: 50%;
    height: 50%;
  }
}

.loader-body_show {
  display: flex;
}
</style>
